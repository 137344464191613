import Vue from 'vue';
import vueLazy from 'vue-lazyload';
import VueLazyLoadVideo from 'vue-lazyload-video'

Vue.use(VueLazyLoadVideo)

Vue.use(vueLazy, {
  adapter: {
    loaded ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error, Init }) {
      el.parentElement.style.height = 'unset';
    },
    loading ({el}) {
      let parent = el.parentElement;
      let parentW = parent.getBoundingClientRect().width;
      let ratio = el.getAttribute('data-width') / parentW;
      parent.style.height = (parseFloat(el.getAttribute('data-height')) / ratio).toFixed(1) + 'px';

      if (el.getAttribute('sets-loaded') !== 'true') {
        let sets = el.getAttribute('data-srcset').split(', ');

        let i = Number.MAX_SAFE_INTEGER;
        let newSets = [];

        sets.forEach((set, index) => {
          let src = el.getAttribute('data-src');
          if (set.split(' ')[0] === src) {
            i = index;
          }

          if (index >= i) {
            newSets.push(set);
          }
        });

        el.setAttribute('data-srcset', newSets.join(', '))
        el.setAttribute('sets-loaded', 'true');
      }
    }
  }
});

import GalleryApp from './components/GalleryApp.vue'

new Vue({
  el: '#galleryApp',
  components: {
    'gallery-app': GalleryApp
  }
});

window.addEventListener('readystatechange', (event) => {
  console.log(`${window.readyState}`);
});
