<script>
import _debounce from 'lodash';
import axios from 'axios';

export default {
  data() {
    return {
      spacing: localStorage.spacing || 5,
      sizingInput: localStorage.sizing || 7,
      minInputSize: 1,
      maxInputSize: 8,
      maxSize: 11,
      showSettings: false,
      showFilters: false,
      windowWidth: window.innerWidth,
      imagesData: this.$props.images || [],
      filters: null,
      activeFilter: parseInt(localStorage.activeFilter || 0),
      reload: true,
      filtered: parseInt(localStorage.filteredCategory || 0) || null,
      filteredValue:  parseInt(localStorage.filteredValue || 0) || null,
      iframeSrc: null,
      page: 1,
      allImages: false,
      loading: false,
      scrollPosition: parseInt(localStorage.scrollPosition || 0),
      allImagesCount: 0,

      breakpoints:  {
        xsm: 400,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1280,
        xlg: 1920,
        xxlg: 2520,
        xlxlg: 3120,
        xxlxlg: 3840
      },

      columnBreakpoints: {
        11: {
          xsm: 4,
          sm: 7,
          md: 8,
          lg: 9,
          xl: 10,
          xlg: 11,
          xxlg: 12,
          xlxlg: 13,
          xxlxlg: 14
        },
        10: {
          xsm: 3,
          sm: 6,
          md: 7,
          lg: 8,
          xl: 9,
          xlg: 10,
          xxlg: 11,
          xlxlg: 12,
          xxlxlg: 13
        },
        9: {
          xsm: 2,
          sm: 5,
          md: 6,
          lg: 7,
          xl: 8,
          xlg: 9,
          xxlg: 10,
          xlxlg: 11,
          xxlxlg: 12
        },
        8: {
          xsm: 1,
          sm: 4,
          md: 5,
          lg: 6,
          xl: 7,
          xlg: 8,
          xxlg: 9,
          xlxlg: 10,
          xxlxlg: 11
        },
        7: {
          xsm: 1,
          sm: 3,
          md: 4,
          lg: 5,
          xl: 6,
          xlg: 7,
          xxlg: 8,
          xlxlg: 9,
          xxlxlg: 10
        },
        6: {
          xsm: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xlg: 6,
          xxlg: 7,
          xlxlg: 8,
          xxlxlg: 9
        },
        5: {
          xsm: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xlg: 5,
          xxlg: 6,
          xlxlg: 7,
          xxlxlg: 8
        },
        4: {
          xsm: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 3,
          xlg: 4,
          xxlg: 5,
          xlxlg: 6,
          xxlxlg: 7
        },
      }
    };
  },

  props: {
    images: {
      type: Array,
      required: false
    },
    urlRandom: {
      type: String,
      required: false
    },
    urlFilters: {
      type: String,
      required: false
    },
    urlPhotos: {
      type: String,
      required: false
    }
  },

  methods: {
    handleResize: _.debounce(function () {
      this.windowWidth = window.innerWidth;

      if (this.windowWidth <= 767) {
        let filterItems = document.getElementsByClassName('ox-search-menu__option-values');
        for (let i = 0; i < filterItems.length; i++ ) {
            filterItems[i].style.display = "block";
        }
      } else {
        let filterItems = document.getElementsByClassName('ox-search-menu__option-values');
        for (let i = 0; i < filterItems.length; i++ ) {
            filterItems[i].style.display = "none";
        }
        let filterActiveOption = document.querySelector('.ox-search-menu__option-values[category="'+this.activeFilter+'"]');
        if (filterActiveOption) {
          filterActiveOption.style.display = "block";
        }
      }

    }, 100),

    getBreakpoint: function (size) {
      if (this.windowWidth >= this.breakpoints.xxlxlg) {
        size = this.columnBreakpoints[size].xxlxlg;
      } else if (this.windowWidth >= this.breakpoints.xlxlg) {
        size = this.columnBreakpoints[size].xlxlg;
      } else if (this.windowWidth >= this.breakpoints.xxlg) {
        size = this.columnBreakpoints[size].xxlg;
      } else if (this.windowWidth >= this.breakpoints.xlg) {
        size = this.columnBreakpoints[size].xlg;
      } else if (this.windowWidth >= this.breakpoints.xl) {
        size = this.columnBreakpoints[size].xl;
      } else if (this.windowWidth >= this.breakpoints.lg) {
        size = this.columnBreakpoints[size].lg;
      } else if (this.windowWidth >= this.breakpoints.md) {
        size = this.columnBreakpoints[size].md;
      } else if (this.windowWidth >= this.breakpoints.sm) {
        size = this.columnBreakpoints[size].sm;
      } else if (this.windowWidth >= this.breakpoints.xsm) {
        size = this.columnBreakpoints[size].xsm;
      } else {
        size = size >= 9 ? 2 : 1;
      }

      return size;
    },

    spacingChanged: function () {
      localStorage.spacing = this.spacing;
    },

    randomImages: function () {
      let first = document.querySelector('.ox-grid-loader');
      first.classList.remove('d-none');
      first.classList.remove('ox-grid-loader-initial');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.imagesData = [];
      this.allImages = false;
      this.filtered = null;
      localStorage.setItem('isInitialRandom',true);
      this.getImages(this.$props.urlPhotos);
      localStorage.removeItem('filtered');
      localStorage.removeItem('filteredCategory');
      localStorage.removeItem('filteredValue');
    },

    toggleDropWindow(el, isShow) {
      let dropEl = document.querySelector(`#${el}`);
      if(isShow) {
        dropEl.classList.add('ox-header__dropwindow--in-anim');
        setTimeout(function() {
          dropEl.classList.remove('ox-header__dropwindow--in-anim');
          dropEl.classList.add('ox-header__dropwindow--active');
        }, 400);
      } else {
        dropEl.classList.remove('ox-header__dropwindow--active');
      }
    },

    toggleMenu(isShow) {
      let menuEl = document.querySelector('#menu');
      if(isShow) {
        menuEl.classList.add('ox-menu--in-anim');
        document.body.classList.add('ox-u-menu-open');
        setTimeout(function() {
          menuEl.classList.remove('ox-menu--in-anim');
          menuEl.classList.add('ox-menu--active');
        }, 500);
      } else {
        menuEl.classList.remove('ox-menu--active');
        document.body.classList.remove('ox-u-menu-open');
      }
    },

    getFilters: function () {
      if (! this.filters && this.$props.urlFilters) {
        axios
          .post(this.$props.urlFilters)
          .then(response => {
            this.filters = response.data.filters;
          });
      }
      console.log(this.filters);
    },

    getImages: function (url, onScroll = false) {
        if (url) {
            this.loading = true;

            axios
                .post(url,
                    {
                        filter: {
                            category: this.activeFilter,
                            value: this.filtered,
                            images: onScroll ? this.imagesData.map(x => x.id) : []
                        }
          })
          .then(response => {
              let images = response.data.data.images;

              if (images.length === 0) {
                  this.allImages = true;
              }
              if (response.data.data.all > 0) {
                  this.allImagesCount = response.data.data.all;
              }

              if (this.imagesData.length === 0) {
                  this.imagesData = images;

              } else {
                  this.imagesData = this.imagesData.concat(images);
              }

              if (!this.filtered) {
                  localStorage.oxInitialGallery = JSON.stringify(this.imagesData);
              }
              localStorage.oxGalleryData = JSON.stringify(this.imagesData);

              this.loading = false;
          })
                .catch(error => {
                    this.allImages = true;
                    this.loading = false;
                });
            let slug='';
            console.log([this.activeFilter, this.filters, this.filters[this.activeFilter]]);
            if (this.filters && this.filteredValue !== null && this.filters[this.activeFilter] && this.filters[this.activeFilter].values) {
              if(typeof this.filters[this.activeFilter].values === 'object') {
                let filterValues = this.filters[this.activeFilter].values;
                this.filters[this.activeFilter].values = Object.entries(filterValues).map(([k, v]) => (v));
              }
              if(this.filters[this.activeFilter].values.find(value => value.slug == this.filteredValue)) {
                slug = '?' + this.filters[this.activeFilter].values.find(value => value.slug == this.filteredValue).slug;
              }
            }
            window.history.pushState(slug,
                "", slug);
        }
    },

    getHeight: function (event) {
      console.log(event.target.parentElement.getAttribute('data-image'));
    },

    changeFilterCategory: function (index) {
      if (this.windowWidth >= 768) {
        this.handleFilteredUncheck(this.filtered, index, false);
        this.getInitialData();
        let filterItems = document.getElementsByClassName('ox-search-menu__option-values');
        for (let i = 0; i < filterItems.length; i++ ) {
            filterItems[i].style.display = "none";
        }

        document.querySelector('.ox-search-menu__option-values[category="'+index+'"]').style.display = "block";

        this.filtered = null;
        this.filteredValue = null;
      }
    },

    handleFilteredUncheck: function (value, activeFilter, shouldHide = true) {

        window.history.pushState({}, '', window.location.origin)
        let shouldReload = false;
        this.activeFilter = parseInt(activeFilter);
        localStorage.activeFilter = parseInt(activeFilter);

        this.filteredValue = value

        if (this.filtered == value) {
            shouldReload = this.filtered !== null;
            this.filtered = null;
            this.filteredValue = null;
        localStorage.removeItem('filteredCategory');
        localStorage.removeItem('filteredValue');
        localStorage.activeFilter = this.activeFilter;
        localStorage.oxGalleryData = localStorage.oxInitialGallery;

        if (shouldReload) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.getInitialData();
        }
      } else {
        shouldReload = true;
        this.filtered = value
        localStorage.filteredCategory = this.filtered;
        localStorage.filteredValue = this.filteredValue;

        if (shouldReload) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.imagesData = [];
          this.allImages = false;
          this.getImages(this.$props.urlPhotos);
        }
      }

      if (shouldHide) {
        this.toggleDropWindow('filters', false);
      }
    },

    playVideo: function (event) {
      event.target.play();
    },

    setIframeSrc: function (url) {
      this.iframeSrc = url;
      this.$forceUpdate();
    },

    handleScroll: function () {
      localStorage.scrollPosition = window.pageYOffset;
      let windowPosition = window.pageYOffset + window.innerHeight;

      if ((windowPosition > this.scrollBottom) && ! this.allImages && ! this.loading) {
        this.getImages(this.$props.urlPhotos, true);
      }
    },

    itemsCount()
    {
      return this.imagesData.length;
    },

    itemsCountStr()
    {
      let length = this.imagesData.length;

      if (length == 0 || length > 4) {
        return length + ' položek';
      } else if (length > 1) {
        return length + ' položky';
      } else {
        return length + ' položku';
      }
    },

    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    getInitialData() {
        this.allImagesCount = 0;
        let isInitialRandom = localStorage.getItem('isInitialRandom') === "true" ? true : false ;

        if (this.getCookie('introSlider') !== '1' || isInitialRandom == false) {
            // if introSlider is set
            localStorage.setItem('isInitialRandom',true);
            this.randomImages();
        }
        else {
            if (localStorage.oxGalleryData) {
                if (isInitialRandom == true){
                    let images = JSON.parse(localStorage.oxInitialGallery);
                    this.imagesData = images;
                    this.allImagesCount = this.imagesData.length;
                }else{
                    let images = JSON.parse(localStorage.oxGalleryData);
                    this.imagesData = images;
                    this.allImagesCount = this.imagesData.length;
                }
            } else {
                this.getImages(this.$props.urlPhotos);
            }
        }
    },
      setFilters() {
          let url = window.location;
          if (url.search.length > 0) {
              let filter = url.search.split('-', 2)[0].substring(1);
              console.log(filter);
              let value = url.search.substring(1);
              if (filter == 'barva' || filter == 'material' || filter == 'prostor' || filter == 'klient') {
                  this.activeFilter = 1;
              }
              /*
              if (filter == 'material') {
                  this.activeFilter = 1;
              }
              if (filter == 'prostor') {
                  this.activeFilter = 2;
              }
              if (filter == 'klient') {
                  this.activeFilter = 3;
              }
              */
              else {
                this.activeFilter = 0;
              }
              let values = this.filters[this.activeFilter].values;
              let filterValue= '';
              Object.keys(values).forEach(function(key) {
                    if(values[key].slug === value){
                        filterValue = key;
                    }
              });
              this.filtered = filterValue ;
              this.filteredValue = filterValue ;
          }

      }
  },

    mounted() {
      window.swipers = [];
      const interval = setInterval(() => {
        if (this.$refs.swiperRef) {
          this.$refs.swiperRef.forEach(function(element) {
            setSwiper(element, window.swipers);
          });
          clearInterval(interval);
        }
      }, 50);
    },

    created() {
        this.filtered = null;
        this.filteredValue = null;
        localStorage.removeItem('filteredCategory');
        localStorage.removeItem('filteredValue');
        if ((document.referrer && document.referrer.includes("?"))) {
            this.imagesData = [];
        }
        axios
            .post(this.$props.urlFilters)
            .then(response => {
                this.filters = response.data.filters;
            }).then(() => {
            this.setFilters();
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
            // handle window resize
            if (window.location.search.length > 0 ) {
                this.getImages(this.$props.urlPhotos)
            }else{
                this.getInitialData()
            }
            // handle scroll
            window.addEventListener('scroll', this.handleScroll);
        });
    },

    computed: {
        sizeClass() {
            return `ox-grid__cols--${this.sizing}`;
        },

        spaceClass() {
            return `ox-grid__cols--space-${parseInt(this.spacing) - 1}`;
    },

    columns() {
      let array = [];

      if (! this.imagesData) {
        return [];
      }

      let sizing = this.columnCount;
      for (let i = 0; i < this.imagesData.length; i++) {
        if (i < sizing) {
          array.push([]);
        }

        array[i % sizing].push(i);
      }

      return array;
    },

    sizing() {
      let size = this.maxSize - parseInt(this.sizingInput) + 1;

      localStorage.sizing = this.sizingInput;
      return size;
    },

    columnCount() {
      let size = this.sizing;

      return this.getBreakpoint(size);
    },

    scrollBottom() {
      let columns = this.columns;

      let flex = document.getElementById('flexParent');
      let minHeight = Number.MAX_SAFE_INTEGER;

      Array.from(flex.children).forEach(div => {
        minHeight = div.clientHeight < minHeight ? div.clientHeight : minHeight;
      });

      return minHeight;
    }
  },
  watch: {
    loading: function (value) {
      let elem = document.querySelector('.ox-grid-loader');
      let first = document.querySelector('.ox-grid-loader.ox-grid-loader-initial');

      if (elem) {
        if (value) {
          if(first) {
           elem.classList.remove('d-none');
           elem.classList.remove('ox-grid-loader-initial');
          }
        } else {
          elem.classList.add('d-none');
        }
      }
    },

    allImagesCount: function (value) {
      localStorage.oxAllImagesCount = value;
    }
  }
}
</script>
